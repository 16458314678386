import React, { useMemo } from 'react';
import RootContext, { RootContext as RootContextType } from './RootContext';
import resources from '../../assets/locale';

const RootProvider = ({ children, ...props }: RootContextType) => {
  const {
    v1: { localization }
  } = props;

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  );

  return (
    <RootContext.Provider value={props}>
      <TranslatorProvider resources={resources}>{children}</TranslatorProvider>
    </RootContext.Provider>
  );
};

export default RootProvider;
