import React, { useState, useEffect, useMemo } from 'react';
import Button from '@veneer/core/dist/scripts/button';

import TenantLevelEnum from '../../utils/TenantLevelEnum';
import { useRootContext } from '../../contexts/Root';
import { CustomerSelectorController } from './CustomerSelectorController';

const paginationSize = 10;

const CustomerAnchor = ({ isHidden, isDisabled, properties }) => {
  const {
    v1: { tenantHandler, navigation, logger }
  } = useRootContext();
  const { pathForCustomerPage } = properties || {};
  const [currentCustomer, setCurrentCustomer] = useState('');

  const customerSelectorController = useMemo(
    () =>
      new CustomerSelectorController({
        tenantHandler,
        paginationSize
      }),
    [tenantHandler, paginationSize]
  );

  const tenantId = useMemo(
    () => tenantHandler?.getTenantId(TenantLevelEnum.tenant),
    [tenantHandler]
  );

  useEffect(() => {
    (async function () {
      const customerId = tenantHandler?.getTenantId(TenantLevelEnum.subTenant);
      let customer;
      if (customerId) {
        customer = await customerSelectorController.getCustomerDetails({
          nodeId: customerId
        });
      } else {
        const customers = await customerSelectorController.getCustomers(
          tenantId
        );
        customer = customers?.tenants?.[0];
      }
      setCurrentCustomer(customer?.accountName || '');
    })();
  }, []);

  const redirectForCustomerList = () => {
    if (pathForCustomerPage) {
      navigation.push(pathForCustomerPage);
    } else {
      (logger as any)?.error?.(
        'Missing pathForSmallDevices property in manifest.'
      );
    }
  };

  if (isHidden) return null;

  return (
    <Button
      disabled={isDisabled}
      onClick={redirectForCustomerList}
      appearance="tertiary"
    >
      {currentCustomer}
    </Button>
  );
};

export default CustomerAnchor;
