import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http';

export default async function getAccessPolicies(
  authProvider: JarvisAuthProvider
) {
  const webClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider
  });

  const payload = await webClient.get({
    url: `/api/session/v1/scopes`
  });

  return payload?.data?.scopes || [];
}
