import React from 'react';
import App from '../src/index';
import packageInfo from '../package.json';
import { ShellProps } from '../src/types/shell';
import RootProvider from '../src/contexts/Root/RootProvider';
declare global {
  interface Window {
    Shell: ShellProps;
  }
}

export default function Root(props) {
  const { v1, v2 } = window.Shell as ShellProps;

  const mfeProps = { ...props, v1, v2, id: packageInfo.name };

  return (
    <RootProvider {...mfeProps}>
      <App {...mfeProps} />
    </RootProvider>
  );
}
