import { TenantHandlerService } from '@jarvis/shell-commons/dist/services/tenantHandler';
import TenantStrategy from '@jarvis/shell-commons/dist/services/tenantHandler/strategy/TenantStrategy';
import { PaginatedTenantVisualizationType } from '@jarvis/shell-commons/dist/services/tenantHandler/strategy/types';
import TenantLevelEnum from '../../utils/TenantLevelEnum';

export type CustomerSelectorControllerProps = {
  tenantHandler: TenantHandlerService;
  paginationSize: number;
};

export class CustomerSelectorController {
  private tenantSelector: TenantStrategy;
  private subTenantSelector: TenantStrategy;
  private paginationSize: number;

  constructor({
    tenantHandler,
    paginationSize = 10
  }: CustomerSelectorControllerProps) {
    this.tenantSelector = tenantHandler.getTenantStrategy(
      TenantLevelEnum.tenant
    );
    this.subTenantSelector = tenantHandler.getTenantStrategy(
      TenantLevelEnum.subTenant
    );
    this.paginationSize = paginationSize;
  }

  async getCustomerDetails(customerHeader?: any) {
    try {
      const customer = await this.tenantSelector?.getTenantById(
        customerHeader.nodeId
      );
      return {
        ...customer,
        nodeId: customer.id,
        value: customer.id,
        label: customer.name
      };
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async getCustomers(
    orgTentantId: string,
    currentPageNumber = 0
  ): Promise<PaginatedTenantVisualizationType> {
    const options = {
      tenantId: orgTentantId,
      page: currentPageNumber,
      paginationSize: this.paginationSize
    };
    return this.subTenantSelector?.getPaginatedTenantList(options);
  }
}
