import packageJson from '../../package.json';

const createIdHelper = (id: string, replacement = '-'): string => {
  const name = packageJson.name
    .replace('@', '')
    .split('/')
    .join(replacement)
    .split('-')
    .join(replacement);
  return `${name}__${id}`;
};

export default createIdHelper;
