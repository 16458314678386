import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Select from '@veneer/core/dist/scripts/select';
import { SelectWrapper } from './styles';
import { useRootContext } from '../../contexts/Root';
import { CustomerSelectorController } from './CustomerSelectorController';
import createIdHelper from '../../utils/createIdHelper';
import TenantLevelEnum from '../../utils/TenantLevelEnum';
import { TenantVisualizationType } from '@jarvis/shell-commons/dist/services/tenantHandler/strategy/types';

const DEFAULT_PAGINATION_SIZE = 10;

type TenantData = {
  nodeId: string;
  value: string;
  label: string;
};

const CustomerSelectorBox = ({ isHidden, isDisabled, properties }) => {
  const {
    v1: { localization, tenantHandler }
  } = useRootContext();
  const { t } = localization.useReactTranslatorHook();
  const [selectedOption, setSelectedOption] = useState('None');
  const [customerList, setCustomerList] = useState([] as TenantData[]);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const { paginationSize = DEFAULT_PAGINATION_SIZE } = properties;
  const visibleOptions = paginationSize - 1;

  const selectCustomer = useRef(null);

  const tentantId = useMemo(
    () => tenantHandler?.getTenantId(TenantLevelEnum.tenant),
    [tenantHandler]
  );

  const customerSelectorController = useMemo(
    () =>
      new CustomerSelectorController({
        tenantHandler,
        paginationSize
      }),
    [tenantHandler, paginationSize]
  );

  const customerSelectorId = useMemo(() => {
    return createIdHelper('customer-selector');
  }, []);

  const setCurrentCustomer = useCallback(
    async ({ value, reload = true }) => {
      if (!value) return;
      setSelectedOption(value);
      const options = { reload };
      await tenantHandler?.setTenant(
        value,
        TenantLevelEnum.subTenant,
        options,
        null
      );
    },
    [tenantHandler]
  );

  const removeCurrentCustomerFromIncomingCustomerList = (
    customersDetails
  ): TenantVisualizationType[] => {
    const customerId = tenantHandler?.getTenantId(TenantLevelEnum.subTenant);
    let newCustomerDetails = customersDetails;
    if (customerId) {
      newCustomerDetails = customersDetails.filter(
        (detail) => detail.id !== customerId && detail.nodeId !== customerId
      );
    }
    return newCustomerDetails;
  };

  const onScrollEndLoadCustomers = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (hasNextPage) {
        await loadCustomers();
      }
      setIsLoading(false);
    }
  };

  const loadCustomers = useCallback(async () => {
    const customers = await customerSelectorController.getCustomers(
      tentantId,
      currentPageNumber
    );

    if (currentPageNumber < customers.totalPages - 1) {
      setCurrentPageNumber(currentPageNumber + 1);
    } else {
      setHasNextPage(false);
    }

    const customerDetails: any[] =
      removeCurrentCustomerFromIncomingCustomerList(customers?.tenants);

    setCustomerList((prev) => [
      ...prev,
      ...customerDetails.map((customer) => {
        return {
          nodeId: customer?.id,
          value: customer?.id,
          label: customer?.name
        };
      })
    ]);
  }, [customerSelectorController, tentantId, currentPageNumber]);

  useEffect(() => {
    (async () => {
      await loadCustomers();
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const isfirstChange = selectedOption === 'None';
      if (isfirstChange) {
        const customerId = tenantHandler?.getTenantId(
          TenantLevelEnum.subTenant
        );
        const customer = customerList.find(
          (customer) => customer.nodeId === customerId
        );
        if (customer) {
          setSelectedOption(customer.nodeId);
        } else if (!customer && customerId) {
          const currentCustomerDetails =
            await customerSelectorController.getCustomerDetails({
              nodeId: customerId
            });
          const adaptedCustomerDetails: TenantData = {
            label: currentCustomerDetails.label,
            nodeId: currentCustomerDetails.nodeId,
            value: currentCustomerDetails.value
          };

          setCustomerList((prev) => [...prev, adaptedCustomerDetails]);
          setSelectedOption(adaptedCustomerDetails.nodeId);
        } else if (customerList.length) {
          await setCurrentCustomer({ value: customerList[0].nodeId });
        }
      }
    })();
  }, [customerList]);

  if (isHidden || customerList.length === 0) return null;

  return (
    <SelectWrapper ref={selectCustomer}>
      <Select
        id={customerSelectorId}
        disabled={isDisabled}
        options={customerList}
        label={t('customer-selector.select-text', 'Select customer')}
        value={[selectedOption]}
        clearIcon={false}
        onChange={setCurrentCustomer}
        visibleOptions={visibleOptions}
        onScrollEnd={onScrollEndLoadCustomers}
        scrollEndOffset={0}
      />
    </SelectWrapper>
  );
};

export default CustomerSelectorBox;
