import { createContext } from 'react';
import { MfePropsType } from '../../types/mfeProps';
import { AppFCPropsWithChildrenType } from '../../types/customReactTypes';
import { V1Type, V2Type } from '../../types/shell';

export type RootContext = AppFCPropsWithChildrenType &
  MfePropsType & { v1: V1Type } & { v2: V2Type };

const RootContext = createContext({} as RootContext);

export default RootContext;
